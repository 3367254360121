export const menuItems = [
  // { to: '/investors', item: 'investors' }, // Временная мера для отключения
  { to: '/seller', item: 'seller' },
]

export const menuMobileItems = [
  { to: '/', item: 'home' },
  ...menuItems,
]

export const authNavButtons = [
  {
    to: '/auth/login',
    button: 'login',
    className: ['common-button', 'common-button--color'],
  },
  {
    to: '/auth/registration',
    button: 'registration',
    className: ['common-button', 'common-button--colorless'],
  },
]

export const accountCustomerMenu = [
  { to: '/account-customer/layouts', item: 'layouts', icon: 'IconObjects' },
  { to: '/account-customer/favorites', item: 'favorites', icon: 'IconLike' },
  { to: '/account-customer/chat', item: 'chats', icon: 'IconChat' },
  { to: '/account-customer/requests', item: 'request', icon: 'IconRequests' },
  // {
  //   to: '/account-customer/purchases',
  //   item: 'purchases',
  //   icon: 'IconPurchases',
  // },
  { to: '/account-customer/settings', item: 'settings', icon: 'IconSettings' },
  { to: '', item: 'logout', icon: 'IconLogout', method: true },
]

export const footerMenu = [
  { title: 'news', to: '', nav: [] },
  { title: 'rates', to: '', nav: [] },
  { title: 'features', to: '', nav: [] },
  { title: 'contacts', to: '', nav: [] },
]
