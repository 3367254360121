<script setup lang="ts">
const props = defineProps<{
  iconName: string
  fill?: string
  width?: string
  height?: string
  bcg?: string
}>()


const iconComponent = defineAsyncComponent(() => import(`~/components/icon/${props.iconName}.vue`))
</script>

<template>
  <component 
    :is="iconComponent" 
    :fill="fill"
    :width="width"
    :height="height"
    :bcg="bcg"
  />
</template>

<style lang='scss' scoped>
</style>