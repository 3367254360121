<script setup lang="ts">
</script>

<template>
  <div class="project">
    <NuxtLink 
      to="https://t.me/ivanproskura"
      target="_blank"
      external
      :aria-label="$t('header.discussProject')"
      class="project__img-wrapper"
    >
      <img
        src="/svg/header/telegram-outline.svg"
        alt=""
        width="auto"
        height="auto"
        class="project__img project__img--svg"
      >
      <img
        src="/img/header/face.webp"
        alt=""
        width="auto"
        height="auto"
        class="project__img"
      >
    </NuxtLink>
    <span class="project__text">{{ $t('header.discussProject') }}</span>
  </div>
</template>

<style lang='scss' scoped>
.project {
  display: flex;
  flex-direction: var(--fd, row);
  justify-content: center;
  align-items: var(--ai, center);
  gap: var(--g, 20px);

  &__img-wrapper {
    display: grid;
    grid-template-columns: var(--gtc, 88px);
  }

  &__img {
    grid-column: 1 / -1;
    grid-row: 1 / -1;
    width: var(--w, auto);

    &--svg {
      margin-inline-start: auto;
    }
  }

  &__text {
    font-size: var(--fz, 16px);
  }
}
</style>